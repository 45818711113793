import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';

// styles
import 'assets/css/bootstrap.min.css';
import 'assets/scss/paper-kit.scss';
import 'assets/css/fontawesome.min.css';
import 'assets/css/sweetalert2.min.css';
import 'assets/css/aftercamp.css';

// pages
import LandingPage from 'views/LandingPage.js';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/home" element={<LandingPage />} />
      <Route path="/" element={<Navigate replace to="/home" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
