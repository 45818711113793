import React from 'react';
import { Alert } from 'reactstrap';

const styleCookieNotice = {
  position: 'fixed',
  bottom: '0%',
  width: '100%',
  zIndex: '100',
  marginBottom: '0',
  backgroundColor: '#f5593d',
};

const closeClass = {};

function CookieNotice(props) {
  const [visible, setVisible] = React.useState(true);

  if (visible) {
    return (
      <>
        <div
          color="danger"
          style={styleCookieNotice}
          isOpen={visible}
          toggle={() => setVisible(false)}
          closeClassName={closeClass}
          role="alert"
          class="alert alert-danger alert-dismissible fade show"
        >
          <div style={{ width: '95%', textAlign: 'center' }}>
            <div>
              <p style={{ fontWeight: '500', marginTop: '3px' }}>
                We use cookies.{' '}
                <span
                  onClick={() => props.setModal(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <u>You can read our policy here</u>
                </span>
                . By continuing to use this website, you accept the use of these
                cookies.
              </p>
            </div>
            <div
              style={{
                position: 'absolute',
                right: '18px',
                top: '13px',
                fontWeight: '800',
                cursor: 'pointer',
              }}
              onClick={() => {
                setVisible(false);
              }}
            >
              <b>X</b>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

export default CookieNotice;
