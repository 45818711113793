import React, { useState } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';

// core components
import MultiDropdownNavbar from 'components/Navbars/MultiDropdownNavbar.js';
import LandingPageHeader from 'components/Headers/LandingPageHeader.js';
import Swal from 'sweetalert2';
import axios from 'axios';

import MeetupLogo from '../assets/img/meetup-logo.svg';
import GitHubLogo from '../assets/img/GitHubSVG.svg';
import LinkedInLogo from '../assets/img/LinkedInSVG.svg';
import FacebookLogo from '../assets/img/FacebookSVG.svg';
import HeartLogo from '../assets/img/HeartSVG.svg';

const adjustFontStyle = {
  color: 'black',
  fontWeight: '500',
};

function LandingPage() {
  // document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("landing-page");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("landing-page");
  //   };
  // });

  const [form, setForm] = useState({
    name: '',
    email: '',
    checked: false,
    linkedin: '',
  });

  const postEmail = (event) => {
    event.preventDefault();
    axios
      .post('/api/email', { ...form })
      .then((response) => {
        if (response.status === 202) {
          Swal.fire(
            'We already have you!',
            'We will keep you posted!',
            'success'
          );
        } else {
          Swal.fire('Got it!', 'We will keep you posted!', 'success');
        }

        setForm({
          name: '',
          email: '',
          checked: false,
          linkedin: '',
        });
      })
      .catch((err) => {
        if (err.response.status === 429) {
          Swal.fire({
            icon: 'error',
            title: 'Oh you...',
            text: "We rate limit. Don't bot attack me, yo.",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ope!',
            text: 'Something went wrong. Shoot me an email.',
            footer: 'Email: scott@aftercamp.io',
          });
        }

        setForm({
          name: '',
          email: '',
          checked: false,
          linkedin: '',
        });
      });
  };

  const onFormChange = (input) => (event) => {
    // event.preventDefault();
    if (input === 'checked') {
      setForm({ ...form, [input]: event.target.checked });
    } else {
      setForm({ ...form, [input]: event.target.value });
    }
  };

  return (
    <>
      <MultiDropdownNavbar />
      <LandingPageHeader />

      <div className="wrapper">
        <div
          className="features-1 section-dark"
          id="grow-career"
          style={{ backgroundColor: '#050b0d' }}
        >
          <Container>
            <Row>
              <Col
                className="ml-auto mr-auto text-center aftercamp-clearfont"
                md="8"
              >
                <h2 className="title text-left">
                  We're going to change the way people in the web development
                  industry grow.
                </h2>
                <h5 className="description text-left">
                  We've spent the past decade immersed in the web development
                  industry. From building projects for high profile clients, to
                  teaching the next generation of engineers in development
                  bootcamps and computer science programs.
                </h5>
                <br />
                <h5 className="description text-left">
                  We understand deeply that the industry has changed a lot in
                  the last 10 years - and that it will continue to change.
                </h5>
                <br />
                <h5 className="description text-left">
                  We are rising to meet the growing need to help people in a new
                  way. Our mission is to help graduates engage in structured
                  growth as they prepare for and enter their career as a web
                  developer. As well as give them an opportunity to showcase
                  their abilities to the world, and find a community they can
                  thrive in.
                </h5>
                <br />
                <h5 className="description text-left">
                  Currently, we are in the early stages of our product
                  development - talking to our end-users and expanding on the
                  decade of knowledge we have already. We would love to keep you
                  up-to-date on where we are at, let you know when we hit
                  critical points in the process, and ultimately, when we are
                  ready to launch!
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="12" style={{ marginTop: '40px', textAlign: 'center' }}>
                <Button color="danger" size="lg" href="#join-waitlist">
                  Get Updates
                </Button>
              </Col>
            </Row>
            {/* <Row style={{ marginTop: '20px' }}>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="fa fa-leaf" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Daily Growth</h4>
                    <p className="description">
                      Not just a course, but an endless stream of professional
                      growth, organized into easy to consume, daily modules.
                      Miss a day? No worries, pick up where you left off the
                      next day.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="fa fa-laptop" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Real Challenges</h4>
                    <p>
                      Apply your skills by completing authentic industry
                      challenges. These are not your 'follow along' sort of
                      challenges. Get a project, get your tickets, and get to
                      work. Get stuck? Don't worry, we have content to help you
                      along the way.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="fa fa-star" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Clear Showcase</h4>
                    <p>
                      Ready to show the world what you can do? Great, we have
                      the tools to help you do that. Clearly communicate what
                      you have been working on and what challenges you are ready
                      to face in your next role.
                    </p>
                  </div>
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>

        {/* ********* MEETUP SECTION ********* */}
        <div className="projects-3" id="meetup">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <div className="space-top" />
                <a
                  href="https://www.meetup.com/aftercamp-meetup/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="..."
                    className="img"
                    src={MeetupLogo}
                    style={{ width: '200px' }}
                  />
                </a>
                <h2 className="title">Join our 500+ member community!</h2>
                <p
                  style={{
                    textAlign: 'left',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  We are rising to meet the growing need to help people in a new
                  way. Our mission is to help graduates engage in structured
                  growth as they prepare for and enter their careers as web
                  developers. As well as give them an opportunity to showcase
                  their abilities to the world, and find a community they can
                  thrive in. <br />
                  <br />
                  This meetup is intended for two groups - Those who have just
                  graduated from a development bootcamp or computer science
                  program and those who are in the first couple of years of
                  their web development career. But all are welcome to attend!
                  We will cover what should come next, so you can close the gap
                  between graduation and your first placement. <br />
                  <br />
                  Additionally, we will cover the early stages of your career
                  and how you can own your own personal development, rather than
                  leave it just in the hands of your employer. We are hosting
                  our events online, and plan on bringing in guest speakers from
                  around the United States. We also plan on covering a wide
                  range of topics, such as industry technology trends, what you
                  should be doing in your job hunt, best practices around
                  job-seeking materials, and more. <br />
                  <br />
                  Our unique spin on this topic is that we bring a depth of
                  industry experience, best-in-class presentation skills, and an
                  advanced understanding of the psychology of a job seeker. We
                  know that you are fighting your way through a massive list of
                  courses and meetups, so our promise to you is to be efficient
                  with the time you spend with us. We are excited to meet you
                  and continue to grow with you!
                </p>
                <h5 className="title" style={adjustFontStyle}>
                  Future Sessions:
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=UKm4oqumZ1Y"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-EngMan.png')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Feb 15th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">Engineering Management</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      Engineering Management <br />
                      for New'ish Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.meetup.com/aftercamp-meetup/events/283508349/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-XR.png')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Mar 15th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">eXtended Reality</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      Extended Reality (XR) <br />
                      for New'ish Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.meetup.com/aftercamp-meetup/events/283508458/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-NewLang.png')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Apr 12th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">Learning New Languages</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      Learning New Languages <br />
                      for New'ish Full Stack Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* PAST SESSIONS */}

            <Row>
              <Col className="ml-auto mr-auto text-center">
                <h5 className="title" style={adjustFontStyle}>
                  Past Sessions:
                </h5>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=iY8Zi9bbkAo"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-DockerK8.png')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: Sep 21st @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">Docker and Kubernetes</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      Docker and Kubernetes <br />
                      for New'ish Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=Dn3xG2TQAb8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-12F.png')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: Oct 19th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">12 Factor Apps</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      12 Factor Applications <br />
                      for New'ish Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>

              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=R4VP2jxQhHI"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-Solid.png')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: Nov 16th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">SOLID Principles</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      SOLID Principles <br />
                      for New'ish Full Stack Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=nzAgeVDPjRY"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-FS-AWS.png')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: Sep 7th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">
                      Full Stack Serverless with AWS
                    </CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      Full Stack Serverless development with <br /> AWS Amplify,
                      featuring guest speaker <br /> Michael Liendo from AWS
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=8q96n29nS3M"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-ERDs-etc.jpg')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: Aug 17th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">
                      ERDs, Open API, and Architecture Graphs
                    </CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      An introduction to documenting applications with
                      DBDiagram.io, Swagger UI, and Lucid Chart
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=eRfAZJ_P9gE"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-AWS.jpg')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: July 13th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">Amazon Web Services</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      An introduction to Amazon Web Services (AWS) for
                      Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=GhNiRC_VKUM"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-System.jpg')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: June 15th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">System Architecture</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      System Architecture for New'ish Developers
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=g0jeYpLyLV0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-Python.jpg')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: May 11th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">JavaScript to Python</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      Why you should consider Python and moving from Express to
                      Flask
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.youtube.com/watch?v=0NKPxHck-2M"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require('assets/img/meetups/Meetup-SaHLA.jpg')}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <h6 className="card-category" style={{ color: 'black' }}>
                      Recorded: April 13th @ 6 PM CST
                    </h6>
                    <CardTitle tag="h4">Learning Well</CardTitle>
                    <p className="card-description" style={adjustFontStyle}>
                      Professional Growth in Web Development with the SaHLA
                      Framework
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* ********* END MEETUP SECTION ********* */}

        {/* MEET THE BROMANDERS */}

        <div
          className="team-5 section-image"
          id="meet-us"
          style={{
            background:
              'linear-gradient(90deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), ' +
              'url(' +
              require('assets/img/evgeni-evgeniev.jpg') +
              ') bottom / cover no-repeat',
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Meet the Bromanders</h2>
                <h5 className="description">
                  We won't beat around the bush. Besides having an awesome last
                  name, we are a wife and husband team, committed to helping
                  people. Together, we have served the web development community
                  and it's employers for years now.
                  <br />
                  We are excited to get the opportunity to meet you where you
                  are - and share what we have learned from our past experiences
                  and what we continue to learn from employers.
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/Scott_R.jpg')}
                            style={{ borderRadius: '50%' }}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">Scott Bromander</CardTitle>
                        <h6 className="card-category">Co-Founder</h6>
                        <p className="card-description">
                          Scott is an experienced Software Developer, Educator,
                          and Learning Experience Designer. He has led agency
                          projects for Fortune 500 companies and has started
                          multiple development bootcamp campuses across the
                          United States.
                        </p>
                        <CardFooter>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                'https://www.linkedin.com/in/scott-bromander/',
                                '_blank'
                              );
                            }}
                            aria-label="Scott on LinkedIn"
                          >
                            <img
                              alt="..."
                              className="img"
                              src={LinkedInLogo}
                              style={{ borderRadius: '0' }}
                            />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                'https://github.com/scottbromander',
                                '_blank'
                              );
                            }}
                            aria-label="Scott on Github"
                          >
                            <img alt="..." className="img" src={GitHubLogo} />
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/Rachael.jpg')}
                            style={{ borderRadius: '50%' }}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">Rachael Bromander</CardTitle>
                        <h6 className="card-category">Co-Founder</h6>
                        <p className="card-description">
                          Rachael is a Human Resources, Community Engagement,
                          and Communications expert. People are her passion, and
                          helping them find their inner ability and purpose
                          helps fuel her own.
                        </p>
                        <CardFooter>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                'https://www.linkedin.com/in/rachaelbromander/',
                                '_blank'
                              );
                            }}
                            aria-label="Rachael on LinkedIn"
                          >
                            <img
                              alt="..."
                              className="img"
                              src={LinkedInLogo}
                              style={{ borderRadius: '0' }}
                            />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                'https://github.com/rachaelbromander',
                                '_blank'
                              );
                            }}
                            aria-label="Rachael on Github"
                          >
                            <img alt="..." className="img" src={GitHubLogo} />
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* END MEET THE BROMANDERS */}

        {/* WAIT LIST */}
        <div className="section landing-section" id="join-waitlist">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">STAY IN THE LOOP</h2>
                <Form className="contact-form" onSubmit={postEmail}>
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <Input
                        placeholder="Name"
                        required
                        value={form.name}
                        onChange={onFormChange('name')}
                      />
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <Input
                        type="email"
                        placeholder="Email"
                        required
                        value={form.email}
                        onChange={onFormChange('email')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            value={form.checked}
                            defaultChecked={form.checked}
                            type="checkbox"
                            onChange={onFormChange('checked')}
                          />
                          Want us to reach out and connect with you?{' '}
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  {form.checked && (
                    <Row>
                      <Col md="12">
                        <label>LinkedIn Profile</label>
                        <Input
                          type="text"
                          placeholder="Paste your LinkedIn Link"
                          required
                          value={form.linkedin}
                          onChange={onFormChange('linkedin')}
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="offset-md-4" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Get Updates
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div
        className="social-line social-line-black"
        style={{ backgroundColor: '#050b0d' }}
      >
        <Container>
          <Row>
            <Col md="2" sm="12">
              <h4 className="title">Follow Us</h4>
            </Col>
            <Col md="2" sm="3">
              <Button
                className="btn-neutral"
                color="link"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    'https://www.linkedin.com/company/ocean-avenue-holdings-llc/about/',
                    '_blank'
                  );
                }}
                aria-label="Aftercamp on LinkedIn"
              >
                <img
                  alt="..."
                  className="img"
                  src={LinkedInLogo}
                  style={{
                    width: '24px',
                    marginRight: '8px',
                    borderRadius: '0',
                  }}
                />
                LinkedIn
              </Button>
            </Col>
            <Col md="2" sm="3">
              <Button
                className="btn-neutral"
                color="link"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    'https://www.facebook.com/Ocean-Avenue-Holdings-LLC-100270968435922',
                    '_blank'
                  );
                }}
                aria-label="Ocean Avenue Holdings on Facebook"
              >
                <img
                  alt="..."
                  className="img"
                  src={FacebookLogo}
                  style={{ width: '24px', marginRight: '8px' }}
                />
                Facebook
              </Button>
            </Col>
            <Col md="2" sm="3">
              <Button
                className="btn-neutral"
                color="link"
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://github.com/aftercamp', '_blank');
                }}
                aria-label="Aftercamp on Github"
              >
                <img
                  alt="..."
                  className="img"
                  src={GitHubLogo}
                  style={{ width: '24px', marginRight: '8px' }}
                />
                GitHub
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <footer
        className="footer footer-black footer-big"
        style={{ backgroundColor: '#050b0d' }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="4" sm="4">
              <div className="logo text-left" style={{ fontSize: '12px' }}>
                <p>Aftercamp is a product of</p>
                <h3 style={{ marginTop: '1px' }}>
                  Ocean Avenue Holdings, LLC.
                </h3>
              </div>
            </Col>
            <Col className="offset-md-2" md="6" sm="8">
              <div className="links pull-right aftercamp-footer">
                {/* <ul>
                  <li className="mr-1">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Company
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Team
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Contact
                    </a>
                  </li>
                </ul>
                <hr /> */}
                <div className="copyright">
                  <div className="pull-left">
                    Aftercamp © {new Date().getFullYear()}, made with{' '}
                    <img
                      alt="Love"
                      className="img"
                      src={HeartLogo}
                      style={{ width: '16px', margin: '0 2px' }}
                    />{' '}
                    in KCMO
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default LandingPage;
