import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import CookieNotice from 'components/CookieNotice/CookieNotice';
import CookiePolicyModal from 'components/CookiePoilcy/CookiePolicyModal';

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  const [noticeModal, setNoticeModal] = React.useState(false);

  const setModal = (bool) => setNoticeModal(bool);

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          'translate3d(0,' + windowScrollTop + 'px,0)';
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });

  return (
    <>
      <CookiePolicyModal noticeModal={noticeModal} setModal={setModal} />
      <CookieNotice setModal={setModal} />
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), ' +
            'url(' +
            require('assets/img/sections/david-marcu.jpg') +
            ') center / cover no-repeat',
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto" style={{ textAlign: 'center' }}>
              <h1 className="title aftercamp-header">AftercAmp</h1>
              <h5
                className="description"
                style={{ marginTop: '10px', letterSpacing: '1px' }}
              >
                GROWTH FOR YOUR WEB DEVELOPMENT CAREER
              </h5>
            </div>

            {/* <center style={{ marginTop: '18px', textAlign: 'center' }}>
              <Form className="form-inline">
                <FormGroup className="mx-sm-3">
                  <label className="sr-only" htmlFor="email1">
                    Email
                  </label>
                  <Input
                    id="email1"
                    placeholder="E-mail Address"
                    type="email"
                  ></Input>
                </FormGroup>
                <Button color="primary" type="submit">
                  Join Us
                </Button>
              </Form>
            </center> */}
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
