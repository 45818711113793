import React from 'react';
import { Link } from 'react-router-dom';
// nodejs library that concatenates strings
import classnames from 'classnames';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  NavLink,
  Container,
} from 'reactstrap';

// core components

function MultiDropdownNavbar() {
  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor('white');
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor('navbar-transparent');
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames('fixed-top', navbarColor)}
        id="navbar-main"
        expand="lg"
      >
        <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              to="/"
              tag={Link}
              style={{
                fontFamily: 'equinox',
                textTransform: 'none',
                letterSpacing: '10px',
              }}
            >
              AftercAmp.io
            </NavbarBrand>

            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}
              aria-label="Open Menu"
            >
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <div className="nav-item">
                <NavLink nav="true" href="#grow-career">
                  Grow your career
                </NavLink>
              </div>

              <div className="nav-item">
                <NavLink nav="true" href="#meetup">
                  Meetup
                </NavLink>
              </div>

              <div className="nav-item">
                <NavLink nav="true" href="#meet-us">
                  The Team
                </NavLink>
              </div>

              <li className="nav-item">
                <a href="#join-waitlist" className="btn btn-round btn-danger">
                  Get Updates
                </a>
              </li>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default MultiDropdownNavbar;
